<template>

  <DefaultCrudView
    @switch-inactive="switchInactive"
    @create-new="newTenant"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    :inactiveFilterValue="inactiveFilterValue"
    showInactiveFilter
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="tenants"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :item-class="itemRowStyle"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.app_instance_ids="{ item }">
          <span
            v-for="(appInstance, index) in item.app_instance_ids"
            :key="appInstance"
            color="primary"
            dark
          >
            {{ appInstanceTitleById(appInstance) }}<span v-if="index != item.app_instance_ids.length - 1">, </span>
          </span>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editTenant(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="toTenantAdmin(item)"
              >
                mdi-account-details
              </v-icon>
            </template>
            <span>{{ $t('tenant_admins') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="checkIsMaster()"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="toTenantQuotas(item)"
              >
                mdi-currency-usd
              </v-icon>
            </template>
            <span>{{ $t('tenant_quotas') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="filteredFields" ></DynamicFormContent>
        </template>
      </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import ApplicationInstanceActions from '@/store/core/application_instance/actions-types'
import TenantActions from '@/store/core/tenant/actions-types'
import TenantMutations from '@/store/core/tenant/mutations-types'
import AppActions from '@/store/app/actions-types'
import ThemeActions from '@/store/core/theme/actions-types'
import UserRoles from '@/store/core/user/user-roles'

export default {
  name: "Tenant",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ]),

    ...mapGetters('core/application_instance', [
      'appInstanceTitleById'
    ]),

    ...mapGetters('core/auth', [
      'checkAccessRights'
    ]),

    ...mapState({
      fields: state => state.core.tenant.fields,
      headers: state => state.core.tenant.tableHeaders,
      selectedTenant: state => state.core.tenant.selectedTenant,
      inactiveFilterValue: state => state.core.tenant.inactiveFilterValue,
    }),

    filteredFields() {
      if (this.checkIsMaster()) {
        return this.fields.filter(field => field.name !== 'theme');
      } else {
        return this.fields.filter(field => field.name == 'theme');
      }
    },

    breadcrumbs() {
      return [
        {
          text: this.$t('tenants'),
          prependIcon: this.getIconForCoreSettingsItem('tenants'),
        },
      ]
    },

    selectedThemeId() {
      let themes = this.fields.filter(field => field.name == 'theme')
      if (themes.length > 0) {
        return themes[0].value
      }

      return null
    }
  },

  data() {
    return {
      loading: true,
      tenants: [],
      options: {
        sortBy: ['key'],
        sortDesc: [false],
      },
      itemsLength: 0,
      actions: [],
    }
  },

  mounted() {
    this.loading = true
    this[TenantMutations.SET_SELECTED_TENANT](null)

    this.setActions()

    this[ApplicationInstanceActions.GET_ALL]()
      .then((applicationsInstances) => {
        this[TenantMutations.SET_FIELD_APPLICATIONS_INSTANCES](applicationsInstances.items)
        this.reloadData()
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    }
  },

  methods: {
    ...mapActions('core/application_instance', [
      ApplicationInstanceActions.GET_ALL
    ]),

    ...mapActions('core/tenant', [
      TenantActions.SAVE_TENANT,
      TenantActions.GET_ALL_TENANTS,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('core/theme', [
      ThemeActions.GET_TENANT_AVAILABLE_THEMES,
      ThemeActions.LINK_THEME_TO_TENANT,
      ThemeActions.UNLINK_THEME_TO_TENANT,
    ]),

    ...mapMutations('core/tenant', [
      TenantMutations.SET_FIELD_APPLICATIONS_INSTANCES,
      TenantMutations.SET_SELECTED_TENANT,
      TenantMutations.SET_INACTIVE_FILTER_VAL
    ]),

    newTenant() {
      this[TenantMutations.SET_SELECTED_TENANT](null)
      this.$refs.modal.openModal()
    },

    save() {
      if (this.checkIsMaster()) {
        this.$refs.modal.errorMessage = null
        this.$refs.modal.errorList = []

        this[TenantActions.SAVE_TENANT]()
          .then(() => {
            let message = this.selectedTenant ? 'Tenant edited' : 'Tenant created'
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
            this.$refs.modal.closeModal()
            this.reloadData()
          })
      } else {
        const currentTenantThemeId = this.selectedTenant.theme_id

        if (! currentTenantThemeId && ! this.selectedThemeId) {
          return
        }

        let themeLinkEndpoint = ThemeActions.LINK_THEME_TO_TENANT
        let themeId = this.selectedThemeId

        if (! this.selectedThemeId) {
          themeLinkEndpoint = ThemeActions.UNLINK_THEME_TO_TENANT
          themeId = currentTenantThemeId
        }

        this[themeLinkEndpoint]({tenantId: this.selectedTenant.tenant_id, themeId})
          .then(() => {
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Theme Linked to tentant'))
            this.$refs.modal.closeModal()
            this.reloadData()
          })
      }
    },

    reloadData() {
      let options = {...this.$route.query}
      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      if (options.order == 'key') {
        options.order = 'tenant_key'
      }

      if (!this.inactiveFilterValue) {
        options = {
          ...options,
          'filter[active]' : "true"
        }
      }

      this.loading = true

      this[TenantActions.GET_ALL_TENANTS](options)
        .then((result) => {
          this.tenants = result.items

          this.itemsLength = result.total
          this.loading = false
          this.loading = false
        });
    },

    switchInactive() {
      this[TenantMutations.SET_INACTIVE_FILTER_VAL](!this.inactiveFilterValue)
      this.reloadData()
    },

    editTenant(tenant) {
      this[TenantMutations.SET_SELECTED_TENANT](tenant)

      if (! this.checkIsMaster()) {
        this[ThemeActions.GET_TENANT_AVAILABLE_THEMES](tenant.tenant_id)
          .then((result) => {
            this.fields.find(
              field => { return field.name == "theme" })
              .items = [{ theme_id: null, theme_title: '' }, ...result.items]

            this.loading = false
          })
      }

      this.$refs.modal.openModal()
    },

    toTenantAdmin(item) {
      this.$router.push({
         name: "TenantAdmin",
         params: {tenantId: item.tenant_id}
      })
    },

    toTenantQuotas(item) {
      this.$router.push({
         name: "TenantQuotas",
         params: {tenantId: item.tenant_id}
      })
    },

    getModalTitle() {
      return this.selectedTenant ? this.$t('Edit Tenant #' + this.selectedTenant.tenant_id) : this.$t('New Tenant')
    },

    setActions()
    {
      if (this.checkIsMaster()) {
        this.actions = [...this.actions,
          {
            text: "create_tenant",
            eventToEmit: "create-new",
          }
        ]
      }
    },

    checkIsMaster()
    {
      return this.checkAccessRights([UserRoles.USER_MASTER])
    },

    itemRowStyle(item) {
      return item.active ? '': 'inactive-row'
    },
  }
}

</script>